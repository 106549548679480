import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthTokenService } from './auth-token.service';
import { EmitService } from '@app/ts/services/emit.service';
import { Store } from '@ngrx/store';

import * as fromAuth from '../store/auth';
import { catchError ,tap} from 'rxjs/operators';
import { environment } from '@env/environment';
import * as crypto from 'crypto-browserify';
import { CommonService } from '@app/core/services/common.service';
declare var Buffer: any;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  encryptionKey: string;
  excludeTokenVerification =[]
  constructor(
    private injector: Injector,
    public authToken: AuthTokenService,
    private commonService: CommonService,
    private emitService: EmitService,
    public store: Store<fromAuth.AuthState>
  ) { 
    this.encryptionKey = environment.encryptionKey;
    this.excludeTokenVerification= [`${environment.API_END_POINT[localStorage.getEncryptedItem('domain')]}/api/v1/auth/authlogin`]
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
   
      request = request.clone({
        withCredentials: true
      });
      
      if (request.url.search(environment.API_END_POINT[localStorage.getEncryptedItem('domain')]) === 0) {
        // attach token
        let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.checkCookie)))
        if(cookieExist) {
          cookieExist = JSON.parse(cookieExist);
          if(localStorage.getEncryptedItem('partyID')&&cookieExist&&localStorage.getEncryptedItem('partyID')!=cookieExist['partyID']) {
            localStorage.removeEncryptedItem('userData');
            localStorage.setEncryptedItem('partyID',cookieExist['partyID']);
            location.reload();
          }
        }
       
        return this.handleApiRequest(request, next);
      } else {
        // return next.handle(request);
        return this.handleApi(request,next);
      } 
  }



  handleApiRequest(request, next) {
    const handler = next.handle(request).pipe(
      tap(res=> {
        if (res instanceof HttpResponse) {
               if(res.body.data=='cookie_not_found'){
                this.emitService.subscribeapiError(true);  
               }
                                               
        }
    }),
      catchError((error, caught) => {
        if ((error.status === 401 || error.status === 403 ) && request.url != environment.API_END_POINT[localStorage.getEncryptedItem('domain')]+'auth/authlogin') {
          let currentURL = window.location.href
          window.location.href = environment.LOGIN_URL+encodeURIComponent(this.commonService.encrypt(currentURL))
          // window.location.href=environment.LOGIN_URL;
          // this.store.dispatch(new fromAuth.LogoutAction());
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );

    return handler;
  }
  handleApi(request,next) {
    const handler = next.handle(request).pipe(
      tap(res=> {
          if (res instanceof HttpResponse) {  
                 if(res.body.data=='cookie_not_found'){
                  this.emitService.subscribeapiError(true); 
                 }
                                                  
          }
      }));
    return handler;
   }
}
