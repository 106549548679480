import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { BehaviorSubject } from "rxjs";
import { JsonApiService } from "@app/core/services/json-api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";



// const defaultUser = {
//   username: "Guest"
// }
@Injectable()
export class UserService {
  // public user$ = new BehaviorSubject({...defaultUser});
  httpOptions = {
    headers: new HttpHeaders(),
    withCredentials: true
  };
  constructor(private httpClient: HttpClient) {
    // this.jsonApiService.fetch("/user/login-info.json").subscribe(this.user$)
  }

  // public logout(){
  //   this.user$.next({...defaultUser})
  // }
  logoutUsers$(data) {
    return this.httpClient.post(environment.API_END_POINT[localStorage.getEncryptedItem('domain')] + 'users/logout', data, this.httpOptions).pipe(map((response: Response) => response));

  }

  userData(data){
    return this.httpClient.post(environment.API_END_POINT[localStorage.getEncryptedItem('domain')]+'users/user-profile',data);
  }
}
