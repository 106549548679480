import { NavigationEnd, Router } from '@angular/router';
import { AppService } from './app.service';
import { CommonService } from '@app/core/services/common.service';
import { UserService, LayoutService } from './core/services';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { environment } from '@env/environment';
import { EmitService } from './ts/services/emit.service';
import { VersionCheckService } from "@app/shared/versioncheck/version-check.service";
import { Component, Renderer2, ElementRef } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet> <div class="cookie-consent hide-consent show-consent" *ngIf="showCookieConsent"><span class="cookie-text">{{'common_login_cookie_content' |i18n}}<a [href]="accountsUrl+'privacy-policy'" target="_blank"> {{'common_all_privacy_policy' | i18n}} </a></span><button class="consent-btn mt-4" (click)="hideCookieConsent()"> {{'footer_cookie_gotIt' | i18n}}</button></div>`,})
export class AppComponent{
  currentUrl;
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  action = '';
  showCookieConsent:boolean = true;
  accountsUrl = "";
  companyCookie: any;
  constructor(private renderer: Renderer2, private el: ElementRef,private appService: AppService, private router: Router, private commonService: CommonService, private userService: UserService,private idle: Idle,private layoutService:LayoutService,private emitService: EmitService,private versionCheckService:VersionCheckService){
    this.appService.setLocalStorageFunctions();
    this.accountsUrl = environment.ACCOUNTS_URL[localStorage.getEncryptedItem("domain")]
    let code=window.location.search;
    let urldetail=code.substring(code.indexOf("?")+1,code.indexOf("="))
    if(urldetail!="" && urldetail=="code"){
      let cde=code.substring(code.indexOf("=")+1,code.indexOf("&"));
      let state=code.split('=').pop();
      let continuePath;
      this.commonService.setLinkdInCode(cde);
      if(localStorage.getEncryptedItem("loginFrom"))
      continuePath =localStorage.getEncryptedItem("loginFrom")
      continuePath? this.router.navigate(["/mask",continuePath]) : this.router.navigate(["/mask"])     
    }
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.currentUrl=e.url;
      }
    });
    setInterval(() => {
      let formData = new FormData();
      formData.append("domain","."+localStorage.getEncryptedItem('domain'));
      if(this.currentUrl != "/" && !this.currentUrl.startsWith('/auth')) 
      
      this.appService.createAccessToken(formData).subscribe(data=>{ }); 
    },600000);
    // set idle parameters
    this.idle.setIdle(1800); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(10); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      // cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT";
      let currentURL = window.location.href
      let lastActive;
      lastActive = Date.parse(this.commonService.decrypt(this.commonService.getCookie(this.commonService.encrypt(environment.USER_ACTIVE_CHECK))))
      let timeDiff;
      timeDiff = Date.now() - lastActive;
      let minuteDiff = timeDiff / (1000 * 60)
      if(minuteDiff > 30)
      {
        let domain = localStorage.getEncryptedItem('domain');
        localStorage.clear();
        window.location.href = environment.LOGOUT_URL[domain]+encodeURIComponent(this.commonService.encrypt(currentURL))
      }else{
        this.reset();
      }
    });
    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);
    
    let cookieExist = this.commonService.decrypt(decodeURIComponent(this.commonService.getCookie(environment.checkCookie)));
    if (cookieExist) {
      cookieExist= JSON.parse(cookieExist)
      localStorage.setEncryptedItem('userMail',cookieExist['userMail']);
      localStorage.setEncryptedItem('partyID',cookieExist['partyID']);
      localStorage.setEncryptedItem('userID',cookieExist['userID']);
      localStorage.setEncryptedItem('accId',cookieExist['accId']);
      localStorage.setEncryptedItem('isBexioCompany',cookieExist.hasOwnProperty('isBexioCompany')?cookieExist['isBexioCompany']:'false'); 
    } 
    const host = window.location.host
    const parts = host.split(".");
    parts.shift(); 
    const result = parts.join(".");
    localStorage.setEncryptedItem('domain',result);
    console.log(result);
    
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnInit() {
    $("body").addClass(localStorage.getEncryptedItem('domain').split(".")[0]);
    // DONT'T DELETE ,CODE FOR DYNAMIC STYLE FOR WHITE LABEL

    // console.log('window.location.href',window.location.href);
    // if(window.location.href.indexOf('whiteLabel') == -1){
    //   let formData = new FormData();
    //   formData.append("domain","."+localStorage.getEncryptedItem('domain'));
    //   this.appService.getConfig(formData).subscribe(res=>{
    //     if(res['data'].length != 0){
    //       console.log('res',res['data']);
    //       localStorage.setEncryptedItem('config',JSON.stringify(res['data']))
    //       Object.keys(res['data'].color).forEach(element => {
    //         document.documentElement.style.setProperty(element, res['data'].color[element]);
    //       });
    //     }
        
    //   })
    // }else{
    //   localStorage.removeEncryptedItem('config')
    // }
    // END
     
    // setTimeout(() => {
    //   console.log('564');
    //   this.renderer.setStyle(this.el.nativeElement.ownerDocument.documentElement, '--primary-color', 'red');
    // }, 10000);
    // if(localStorage.getEncryptedItem('domain') == 'rahul.li')
    // {
    //   document.documentElement.style.setProperty('--theme-color-1', 'red');
    // }else{
    //   document.documentElement.style.setProperty('--theme-color-1', 'blue');
    // }
    console.log(environment.STYLES[localStorage.getEncryptedItem('domain')]);
    environment.STYLES[localStorage.getEncryptedItem('domain')].forEach(element => {
      document.documentElement.style.setProperty(Object.keys(element)[0], element[Object.keys(element)[0]]);
      // console.log('element',element);
      
    });
    this.reset();
    this.cookieConsent();

    this.versionCheckService.initVersionCheck('version.json');
  }
  cookieConsent(test?){
    if( this.commonService.getCookie(environment.cookieConsent) == 'false' ){
        this.showCookieConsent = false;
    }else{
      this.showCookieConsent = true;
      this.commonService.setCookie(environment.cookieConsent,"true",10000,environment.cookieDomain,'/');
    }
  }
  hideCookieConsent(delay =0){
    if(this.showCookieConsent){
      setTimeout(()=>{
      $(".cookie-consent").fadeOut(1500); 
      this.showCookieConsent = false ;
      this.commonService.setCookie(environment.cookieConsent,"false",10000,environment.cookieDomain,'/');   
      }, delay)
    }
  }
}

